import { useSetRecoilState } from "recoil";
import SmallButton from "../../components/Button/Small";
import SearchInput from "../../components/SearchInput";
import Th from "../../components/Table/Th";
import {
  atom__adminCreateModalInfo,
  atom__alertModalInfo,
  atom__confirmModalInfo,
} from "../../lib/recoil/modal.atom";
import { useEffect, useRef, useState } from "react";
import { TAdminListItem } from "../../lib/types/admin.type";
import { api_adminDelete, api_adminList } from "../../api/admin";
import { printFormattedDate } from "../../lib/util";

const AdminAccountPage = () => {
  const r__setAdminCreateModalInfo = useSetRecoilState(
    atom__adminCreateModalInfo
  );
  const r__setAlertModalInfo = useSetRecoilState(atom__alertModalInfo);
  const r__setConfirmModalInfo = useSetRecoilState(atom__confirmModalInfo);

  const [list, setList] = useState<TAdminListItem[]>([]);

  /** 삭제버튼 누르면, 컨펌모달 띄우면서 값 삽입 */
  const refDeletingId = useRef("");

  useEffect(() => {
    _fetch();
  }, []);

  const _fetch = () => {
    api_adminList().then((res) => {
      if (!res) return;
      setList(res.list);
    });
  };

  const deleteAdmin = () => {
    api_adminDelete([refDeletingId.current]).then((res) => {
      if (!res) return;
      // 컨펌모달 제거
      r__setConfirmModalInfo(null);
      // 목록 새로고침
      _fetch();
      // 삭제성공 모달 표시
      r__setAlertModalInfo({
        title: "삭제",
        desc: ["삭제가 완료되었습니다."],
        onClickBtn: () => r__setAlertModalInfo(null),
      });
    });
  };

  return (
    <div>
      <div className="flex-row-between-center" style={{ marginBottom: "20px" }}>
        <div className="flex-row-start-center">
          <h2 style={{ marginRight: "30px" }}>운영진 계정 관리</h2>
          <SearchInput
            onClickSearch={(keyword, attribute) => {}}
            attributes={["아이디", "이름"]}
          />
        </div>
        <SmallButton
          text="생성"
          onClick={() => {
            r__setAdminCreateModalInfo({ onCreate: _fetch });
          }}
          width={100}
          color="beige"
        />
      </div>

      <table>
        <thead>
          <Th text="아이디" sort="none" width={200} />
          <Th text="이름" sort="none" width={200} />
          <Th text="생성일" sort="none" width={200} />
          <Th text="전화번호" sort="none" width={200} />
          <Th text="관리" sort="none" />
        </thead>
        <tbody>
          {list.map((adm) => {
            return (
              <tr>
                <td>{adm.uid}</td>
                <td>{adm.name}</td>
                <td>
                  {printFormattedDate(new Date(adm.createdAt), {
                    seperator: ".",
                  })}
                </td>
                <td>{adm.phone}</td>
                <td>
                  <SmallButton
                    text="삭제"
                    color="tomato"
                    width={64}
                    onClick={() => {
                      refDeletingId.current = adm.id;
                      r__setConfirmModalInfo({
                        title: "삭제",
                        desc: [
                          "삭제된 계정은 복구가 불가합니다.",
                          "정말로 삭제하시겠습니까?",
                        ],
                        btnRightText: "삭제",
                        onClickBtnLeft: () => r__setConfirmModalInfo(null),
                        onClickBtnRight: deleteAdmin,
                      });
                    }}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default AdminAccountPage;
