import { IButtonCommonProps } from "./Base";

interface IProps extends IButtonCommonProps {
  width: 64 | 100;
  color: "tomato" | "green" | "yellow-dark" | "beige" | "brown";
}
const SmallButton = (props: IProps) => {
  return (
    <button
      disabled={props.disabled}
      className={`btn-small ${props.class}`}
      style={{
        width: props.width,
        backgroundColor: `var(--${props.color}, var(--border-gray))`,
        ...(props.disabled
          ? {
              backgroundColor: "var(--border-gray)",
              color: "#fff",
            }
          : {}),
        ...(props.style ?? {}),
      }}
      onClick={props.onClick}
    >
      {props.text}
    </button>
  );
};

export default SmallButton;
