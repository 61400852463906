import { useEffect, useMemo, useRef, useState } from "react";
import SearchInput from "../../components/SearchInput";
import Th from "../../components/Table/Th";
import { insertComma, printFormattedDate } from "../../lib/util";
import Select from "../../components/Select";
import iconMedalGold from "../../assets/medal_gold.png";
import iconMedalSilve from "../../assets/medal_silver.png";
import iconMedalBronze from "../../assets/medal_bronze.png";
import SmallButton from "../../components/Button/Small";
import { useSetRecoilState } from "recoil";
import { atom__depositCreateModalInfo } from "../../lib/recoil/modal.atom";
import { api_depositList } from "../../api/deposit";
import { TDeposit } from "../../lib/types/deposit.type";
import Pagination from "../../components/Table/Pagination";

const DepositListPage = () => {
  const r__setCreateModalInfo = useSetRecoilState(atom__depositCreateModalInfo);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const [list, setList] = useState<TDeposit[]>([]);

  const limit = useMemo(() => 10, []);
  const refSearchAttribute = useRef("");
  const refSearchKeyword = useRef("");

  useEffect(() => {
    _fetch({ page: 1, needTotalCount: "y" });
  }, []);

  const _fetch = ({
    page,
    needTotalCount,
  }: {
    page: number;
    needTotalCount?: string;
  }) => {
    api_depositList({
      from: limit * (page - 1),
      limit,
      searchField: refSearchAttribute.current || undefined,
      searchKeyword: refSearchKeyword.current || undefined,
      needTotalCount,
    }).then((res) => {
      if (!res) return;
      setList(res.list);

      if (needTotalCount === "y") {
        setTotalCount(res.totalCount ?? 0);
      }
    });
  };

  return (
    <div className="table-container">
      <div className="flex-row-between-center" style={{ marginBottom: "20px" }}>
        <div className="flex-row-start-center">
          <h2 style={{ marginRight: "30px" }}>입금 내역</h2>
          <SearchInput
            onClickSearch={(keyword, attribute) => {
              refSearchAttribute.current = attribute;
              refSearchKeyword.current = keyword.trim();
              setCurrentPage(1);
              _fetch({ page: 1, needTotalCount: "y" });
            }}
            attributes={["입금자", "담당자"]}
          />
        </div>

        <SmallButton
          text="입력"
          onClick={() => {
            r__setCreateModalInfo({
              onCreate: () =>
                _fetch({ page: currentPage, needTotalCount: "y" }),
            });
          }}
          width={100}
          color="beige"
        />
      </div>

      <div style={{ flex: 1 }}>
        <table>
          <thead>
            <Th text="입금일" sort="none" width={150} />
            <Th text="입금자(전화번호)" sort="none" width={200} textSmall />
            <Th text="입금은행" sort="none" width={150} />
            <Th text="입금계좌" sort="none" width={200} />
            <Th text="금액" sort="none" width={180} />
            <Th text="비고" sort="none" width={200} />
            <Th text="입력 담당자" sort="none" />
          </thead>
          <tbody>
            {list.map((deposit) => {
              return (
                <tr>
                  <td>{printFormattedDate(new Date(deposit.depositDate))}</td>
                  <td>{deposit.name}</td>
                  <td>{deposit.bank}</td>
                  <td className="small">{deposit.account}</td>
                  <td>{insertComma(deposit.amount)}원</td>
                  <td>{deposit.memo}</td>
                  <td>{deposit.createAdmName}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <Pagination
        pagePerGroup={10}
        itemPerPage={10}
        currentPage={currentPage}
        totalItem={totalCount}
        onClickPage={(page) => {
          setCurrentPage(page);
          _fetch({ page });
        }}
      />
    </div>
  );
};

export default DepositListPage;
