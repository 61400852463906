import { useEffect, useMemo, useRef, useState } from "react";
import SearchInput from "../../components/SearchInput";
import Th from "../../components/Table/Th";
import { printFormattedDate } from "../../lib/util";
import Select from "../../components/Select";
import iconMedalGold from "../../assets/medal_gold.png";
import iconMedalSilver from "../../assets/medal_silver.png";
import iconMedalBronze from "../../assets/medal_bronze.png";
import SmallButton from "../../components/Button/Small";
import { useSetRecoilState } from "recoil";
import {
  atom__alertModalInfo,
  atom__confirmModalInfo,
  atom__successModalInfo,
} from "../../lib/recoil/modal.atom";
import { TOfficeBadgeListItem } from "../../lib/types/badge.type";
import {
  api_badgeAccept,
  api_badgePendingList,
  api_badgeSms,
} from "../../api/badge";
import Pagination from "../../components/Table/Pagination";
import { badgeImageSrcByWorth } from "../../lib/badge-image";

const BadgePage = () => {
  const r__setSuccessModalInfo = useSetRecoilState(atom__successModalInfo);
  const r__setAlertModalInfo = useSetRecoilState(atom__alertModalInfo);
  const r__setConfirmModalInfo = useSetRecoilState(atom__confirmModalInfo);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const [list, setList] = useState<TOfficeBadgeListItem[]>([]);

  const limit = useMemo(() => 10, []);
  const refSearchAttribute = useRef("");
  const refSearchKeyword = useRef("");

  useEffect(() => {
    _fetch({
      page: 1,
      needTotalCount: "y",
    });
  }, []);

  const _fetch = ({
    page,
    needTotalCount,
  }: {
    page: number;
    needTotalCount?: string;
    userKind?: string;
  }) => {
    api_badgePendingList({
      from: limit * (page - 1),
      limit,
      searchField: refSearchAttribute.current || undefined,
      searchKeyword: refSearchKeyword.current || undefined,
      needTotalCount,
    }).then((res) => {
      if (!res) return;
      setList(res.list);

      if (needTotalCount === "y") {
        setTotalCount(res.totalCount ?? 0);
      }
    });
  };

  const sendMessage = async (badgeId: string) => {
    const res = await api_badgeSms(badgeId);
    if (!res) {
      r__setAlertModalInfo({
        title: "실패",
        desc: ["문자 발송에 실패하였습니다."],
      });
      return;
    }

    r__setSuccessModalInfo({ desc: ["문자를 성공적으로 발송하였습니다."] });
  };

  const approve = async (badgeId: string) => {
    const res = await api_badgeAccept(badgeId);
    if (!res) return;

    r__setConfirmModalInfo(null);
    r__setSuccessModalInfo({ desc: ["승인 되었습니다."] });

    setCurrentPage(1);
    _fetch({ page: 1, needTotalCount: "y" });
  };

  return (
    <div className="table-container">
      <div className="flex-row-between-center" style={{ marginBottom: "20px" }}>
        <div className="flex-row-start-center">
          <h2 style={{ marginRight: "30px" }}>배지 신청 관리</h2>
          <SearchInput
            onClickSearch={(keyword, attribute) => {
              refSearchAttribute.current = attribute;
              refSearchKeyword.current = keyword.trim();
              setCurrentPage(1);
              _fetch({ page: 1, needTotalCount: "y" });
            }}
            attributes={["사무소명"]}
          />
        </div>
      </div>

      <div style={{ flex: 1 }}>
        <table>
          <thead>
            <Th text="배지" sort="none" width={150} />
            <Th text="사무소명" sort="none" width={200} />
            <Th text="주소" sort="none" width={200} />
            <Th text="대표번호" sort="none" />
            <Th text="매물 수" sort="none" width={100} />
            <Th text="지정단지" sort="none" width={280} />
            <Th text="기존 배지 기간" sort="none" />
            <Th text="관리" sort="none" />
          </thead>
          <tbody>
            {list.map((ob) => {
              return (
                <tr key={ob.id}>
                  <td className="small">
                    <div className="flex-row-center-center">
                      <img
                        src={
                          ob.badge.worth === 50
                            ? iconMedalGold
                            : ob.badge.worth === 30
                            ? iconMedalSilver
                            : iconMedalBronze
                        }
                        style={{
                          width: "32px",
                          height: "32px",
                          marginRight: "10px",
                        }}
                      />
                      <span>{ob.badge.name}</span>
                    </div>
                  </td>
                  <td className="small">{ob.office.name}</td>
                  <td className="small">{ob.office.address}</td>
                  <td>{ob.office.hp}</td>
                  <td>{"50개"}</td>
                  <td className="small">{ob.apart.kaptName}</td>
                  <td className="small">
                    {!!ob.prevBadgeStartDate && !!ob.prevBadgeEndDate
                      ? `${printFormattedDate(
                          new Date(ob.prevBadgeStartDate)
                        )} - ${printFormattedDate(
                          new Date(ob.prevBadgeEndDate)
                        )}`
                      : "-"}
                  </td>
                  <td>
                    <div
                      className="flex-row-center-center"
                      style={{ gap: "10px" }}
                    >
                      <SmallButton
                        text="문자발송"
                        color="beige"
                        width={64}
                        onClick={() => {
                          sendMessage(ob.id);
                        }}
                      />

                      <SmallButton
                        text="승인"
                        color="yellow-dark"
                        width={64}
                        onClick={() => {
                          r__setConfirmModalInfo({
                            title: "배지 승인",
                            desc: [
                              "배지 승인하시겠습니까?",
                              "기존 배지 기간의 다음날부터 적용됩니다.",
                            ],
                            onClickBtnLeft: () => r__setConfirmModalInfo(null),
                            onClickBtnRight: () => {
                              approve(ob.id);
                            },
                            theme: "info",
                          });
                        }}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Pagination
        pagePerGroup={10}
        itemPerPage={10}
        currentPage={currentPage}
        totalItem={totalCount}
        onClickPage={(page) => {
          setCurrentPage(page);
          _fetch({ page });
        }}
      />
    </div>
  );
};

export default BadgePage;
