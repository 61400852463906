import { useEffect, useMemo, useRef, useState } from "react";
import SearchInput from "../../components/SearchInput";
import Th from "../../components/Table/Th";
import { printFormattedDate } from "../../lib/util";
import Select from "../../components/Select";
import iconMedalGold from "../../assets/medal_gold.png";
import iconMedalSilve from "../../assets/medal_silver.png";
import iconMedalBronze from "../../assets/medal_bronze.png";
import SmallButton from "../../components/Button/Small";
import { useSetRecoilState } from "recoil";
import { atom__complaintModalInfo } from "../../lib/recoil/modal.atom";
import { TComplaint } from "../../lib/types/complaint.type";
import Pagination from "../../components/Table/Pagination";
import { api_complaintList } from "../../api/complaint";

const ComplaintPage = () => {
  const r__setComplaintModalInfo = useSetRecoilState(atom__complaintModalInfo);

  const [filterKind, setFilterKind] = useState("none"); // 'none' | '공동중개거부' | '허위매물등록'

  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const [list, setList] = useState<TComplaint[]>([]);

  const limit = useMemo(() => 10, []);
  const refSearchAttribute = useRef("");
  const refSearchKeyword = useRef("");

  useEffect(() => {
    _fetch({
      page: 1,
      needTotalCount: "y",
    });
  }, [filterKind]);

  const _fetch = ({
    page,
    needTotalCount,
  }: {
    page: number;
    needTotalCount?: string;
    userKind?: string;
  }) => {
    api_complaintList({
      from: limit * (page - 1),
      limit,
      searchField: refSearchAttribute.current || undefined,
      searchKeyword: refSearchKeyword.current || undefined,
      needTotalCount,
      reason: filterKind !== "none" ? filterKind : undefined,
    }).then((res) => {
      if (!res) return;
      setList(res.list);

      if (needTotalCount === "y") {
        setTotalCount(res.totalCount ?? 0);
      }
    });
  };

  return (
    <div className="table-container">
      <div className="flex-row-between-center" style={{ marginBottom: "20px" }}>
        <div className="flex-row-start-center">
          <h2 style={{ marginRight: "30px" }}>신고 관리</h2>
          <SearchInput
            onClickSearch={(keyword, attribute) => {
              refSearchAttribute.current = attribute;
              refSearchKeyword.current = keyword.trim();
              setCurrentPage(1);
              _fetch({ page: 1, needTotalCount: "y" });
            }}
            attributes={["신고자", "피신고자"]}
          />
        </div>
        <Select
          data={[
            { label: "유형", value: "none" },
            { label: "공동중개거부", value: "공동중개거부" },
            { label: "허위매물등록", value: "허위매물등록" },
          ]}
          onChange={(v) => {
            console.log("changed: ", v);
            setFilterKind(v);
          }}
          value={filterKind}
        />
      </div>

      <div style={{ flex: 1 }}>
        <table>
          <thead>
            <Th text="신고일" sort="none" width={150} />
            <Th text="신고자" sort="none" width={200} />
            <Th text="피신고자" sort="none" width={100} />
            <Th text="전화번호" sort="none" />
            <Th text="신고유형" sort="none" width={180} />
            <Th text="누적신고" sort="none" width={200} />
            <Th text="상세정보" sort="none" />
            <Th text="답변자" sort="none" />
          </thead>
          <tbody>
            {list.map((complaint) => {
              console.log("complaint: ", complaint);
              return (
                <tr key={complaint.id}>
                  <td>{printFormattedDate(new Date(complaint.createdAt))}</td>
                  <td>{complaint.user.uid}</td>
                  <td>{complaint.targetUser.uid}</td>
                  <td>{complaint.targetUser.phone}</td>
                  <td>{complaint.reason}</td>
                  <td>{complaint.accCount}</td>
                  <td className="small">
                    <SmallButton
                      text="상세"
                      color="brown"
                      width={100}
                      onClick={() => {
                        r__setComplaintModalInfo({
                          complaintId: complaint.id,
                          onCancel: () => {
                            _fetch({ page: currentPage });
                          },
                        });
                      }}
                    />
                  </td>
                  <td className="small">
                    {!!complaint.resolveAdm && !!complaint.answeredAt
                      ? `${complaint.resolveAdm.name} (${printFormattedDate(
                          new Date(complaint.answeredAt),
                          { seperator: "/" }
                        ).substring(2)})`
                      : "-"}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Pagination
        pagePerGroup={10}
        itemPerPage={10}
        currentPage={currentPage}
        totalItem={totalCount}
        onClickPage={(page) => {
          setCurrentPage(page);
          _fetch({ page });
        }}
      />
    </div>
  );
};

export default ComplaintPage;
