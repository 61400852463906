import { useRecoilState, useSetRecoilState } from "recoil";
import ModalBase from "./Base";
import {
  atom__adminCreateModalInfo,
  atom__officeModalInfo,
  atom__successModalInfo,
} from "../../lib/recoil/modal.atom";
import cancel from "../../assets/cancel.png";
import { useCallback, useEffect, useState } from "react";
import CloseCircleButton from "../Button/CloseCircle";
import BaseButton from "../Button/Base";
import { api_adminCreate } from "../../api/admin";
import { regNumber } from "../../lib/validator";
import {
  api_officeCreate,
  api_officeDetail,
  api_officeUpdate,
} from "../../api/office";

const ModalOffice = () => {
  const [r__modalInfo, r__setModalInfo] = useRecoilState(atom__officeModalInfo);
  const r__setSuccessModalInfo = useSetRecoilState(atom__successModalInfo);

  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [registerNum, setRegisterNum] = useState("");
  const [ceoName, setCeoName] = useState("");
  const [hp1, setHp1] = useState("");
  const [hp2, setHp2] = useState("");
  const [hp3, setHp3] = useState("");

  const [canNext, setCanNext] = useState(false);

  const close = useCallback(() => {
    r__setModalInfo(null);
    setAddress("");
    setRegisterNum("");
    setName("");
    setCeoName("");
    setHp1("");
    setHp2("");
    setHp3("");
  }, []);

  useEffect(() => {
    if (!r__modalInfo) {
      setAddress("");
      setRegisterNum("");
      setName("");
      setCeoName("");
      setHp1("");
      setHp2("");
      setHp3("");
      return;
    }

    if (!r__modalInfo.officeId) return;

    // 상세 api
    api_officeDetail(r__modalInfo.officeId).then((res) => {
      if (!res) return;

      setAddress(res.office.address);
      setRegisterNum(res.office.registerNumber);
      setName(res.office.name);
      setCeoName(res.office.ceo);
      if (res.office.hp) {
        const [_1, _2, _3] = res.office.hp.split("-");
        setHp1(_1);
        setHp2(_2);
        setHp3(_3);
      }
    });
  }, [r__modalInfo]);

  useEffect(() => {
    setCanNext(
      name.length > 3 &&
        address.length > 8 &&
        registerNum.length > 2 &&
        ceoName.length > 2
    );
  }, [name, address, registerNum, ceoName, hp1, hp2, hp3]);

  const create = async () => {
    const fullHp =
      hp1.length > 1 && hp2.length > 2 && hp3.length > 2
        ? `${hp1}-${hp2}-${hp3}`
        : null;
    const res = await api_officeCreate({
      name,
      registerNumber: registerNum,
      address,
      ceo: ceoName,
      hp: fullHp,
    });
    if (!res) return;

    r__modalInfo?.onCreate?.();
    close();

    r__setSuccessModalInfo({
      desc: ["사무소가 생성되었습니다."],
      onClickBtn: () => r__setSuccessModalInfo(null),
    });
  };
  const edit = async () => {
    if (!r__modalInfo?.officeId) return;

    const fullHp =
      hp1.length > 1 && hp2.length > 2 && hp3.length > 2
        ? `${hp1}-${hp2}-${hp3}`
        : null;
    const res = await api_officeUpdate(r__modalInfo.officeId, {
      name,
      registerNumber: registerNum,
      address,
      ceo: ceoName,
      hp: fullHp,
    });
    if (!res) return;

    r__modalInfo?.onEdit?.();
    close();

    r__setSuccessModalInfo({
      desc: ["사무소 정보가 수정되었습니다."],
      onClickBtn: () => r__setSuccessModalInfo(null),
    });
  };

  return (
    <ModalBase visible={!!r__modalInfo} onClickOuter={close}>
      <div className="flex-row-between-center" style={{ marginBottom: "40px" }}>
        <h2>{r__modalInfo?.officeId ? "사무소 정보" : "신규 사무소 생성"}</h2>
        <CloseCircleButton onClick={close} />
      </div>

      <label htmlFor="name">사무소명</label>
      <input
        id="name"
        type="text"
        placeholder="사무소명 입력"
        maxLength={50}
        style={{ marginBottom: "20px" }}
        value={name}
        onChange={(e) => {
          setName(e.target.value);
        }}
      />

      <label htmlFor="address">등록주소</label>
      <input
        id="address"
        type="text"
        placeholder="주소 입력"
        maxLength={100}
        style={{ marginBottom: "20px" }}
        value={address}
        onChange={(e) => {
          setAddress(e.target.value);
        }}
      />

      <label htmlFor="registerNum">중개사무소 등록번호</label>
      <input
        id="registerNum"
        type="text"
        placeholder="'-' 포함하여 전체 입력"
        maxLength={30}
        style={{ marginBottom: "20px" }}
        value={registerNum}
        onChange={(e) => {
          setRegisterNum(e.target.value);
        }}
      />

      <label htmlFor="ceoName">대표자</label>
      <input
        id="ceoName"
        type="text"
        placeholder="이름 입력"
        maxLength={30}
        style={{ marginBottom: "20px" }}
        value={ceoName}
        onChange={(e) => {
          setCeoName(e.target.value);
        }}
      />

      <label htmlFor="hp1">대표번호 (유선)</label>
      <div className="flex-row-between-center" style={{ marginBottom: "20px" }}>
        <input
          id="hp1"
          type="text"
          placeholder="031"
          maxLength={3}
          style={{ textAlign: "center", flex: 1, maxWidth: "100px" }}
          value={hp1}
          onChange={(e) => {
            if (e.target.value.length && !regNumber.test(e.target.value))
              return;
            setHp1(e.target.value);
          }}
        />

        <input
          id="hp2"
          type="text"
          placeholder="1234"
          maxLength={4}
          style={{ textAlign: "center", flex: 1, maxWidth: "100px" }}
          value={hp2}
          onChange={(e) => {
            if (e.target.value.length && !regNumber.test(e.target.value))
              return;
            setHp2(e.target.value);
          }}
        />

        <input
          id="hp3"
          type="text"
          placeholder="1234"
          maxLength={4}
          style={{ textAlign: "center", flex: 1, maxWidth: "100px" }}
          value={hp3}
          onChange={(e) => {
            if (e.target.value.length && !regNumber.test(e.target.value))
              return;
            setHp3(e.target.value);
          }}
        />
      </div>

      <BaseButton
        text={r__modalInfo?.officeId ? "수정" : "생성"}
        onClick={r__modalInfo?.officeId ? edit : create}
        disabled={!canNext}
      />
    </ModalBase>
  );
};

export default ModalOffice;
