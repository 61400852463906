import { TAdminCreatePayload } from "../lib/types/admin.type";
import { TListQuery } from "../lib/types/common.type";
import axios from "./axios";

export const api_adminCreate = async (payload: TAdminCreatePayload) => {
  const res = await axios.post("/admin", payload);
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data;
};

export const api_adminDelete = async (ids: string[]) => {
  const res = await axios.delete("/admin", { data: { ids } });
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data;
};

export const api_adminList = async () => {
  const res = await axios.get("/admin");
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data;
};
